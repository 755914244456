import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import { graphql } from 'gatsby'

const Content = styled.main`
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1160px;
    ul {
        list-style: disc;
        padding-left: 25px;
    }
`

const Terms = ({ location }) => {
    return (
        <Layout location={location}>
            <Content>
                <h1>Termes et Conditions</h1>
                <p>
                    Les modalités suivantes sont entrées en vigueur depuis 27
                    septembre 2018. Elles peuvent être modifiées en tout temps
                    sans aucun préavis de notre part. En utilisant ce site, vous
                    reconnaissez avoir lu, compris et accepté les conditions qui
                    suivent.
                </p>
                <p>Soumissions Avocat, « LE SERVICE »</p>
                <p>
                    Notre service s’engage à respecter les principes suivants :
                </p>
                <ul>
                    <li>
                        Offrir un service 100% gratuit et sans engagement pour
                        les utilisateurs « demandeurs »
                    </li>
                    <li>Une soumission rapide et concurrentielle</li>
                    <li>Par un réseau de partenaires professionnels</li>
                </ul>
                <p>
                    <strong>NOTRE MISSION: </strong>vous faire économiser du
                    temps et de l’argent en vous permettant de trouver
                    facilement le service légal dont vous avec besoin en
                    fonction de vos critères. Notre service ne favorise aucun de
                    ses partenaires, notre priorité pour vous est de trouver le
                    meilleur prix.
                </p>
                <p>
                    Dans le texte ci-dessous, par souci de concision, le
                    partenaire désigne nos partenaires avocats.
                </p>
                <h2>EXCLUSION DES ENTENTES CONTRACTUELLES</h2>
                <p>
                    Soumissions Avocat agit en tant qu’intermédiaire entre les
                    utilisateurs du service (les demandeurs de soumission) et
                    les partenaires. Nous ne prétendons pas vendre des produits
                    ou services, nous ne sommes pas courtier (d’assurance,
                    immobilier…) ou conseiller. Soumissions Avocat ne détient
                    aucun autre titre, que ce soit au nom des compagnies
                    d’assurance, institution financière, etc.
                </p>
                <p>
                    Soumissions Avocat ne peut être inclus dans un contrat
                    émanant d’une mise en contact (demandeur/partenaire) via
                    notre plateforme web.
                </p>
                <p>
                    Soumissions Avocat ne peut être tenu responsable des
                    produits ou services vendus ou de tout préjudice causé par
                    le client (demandeur) ou le partenaire.
                </p>
                <h2>CONFIDENTIALITÉ DE VOS INFORMATIONS</h2>
                <p>
                    Soumissions Avocat s’engage à respecter la Loi sur la
                    protection des renseignements personnels dans le secteur
                    privé.
                </p>
                <h2>UTILISATION DE VOS INFORMATIONS</h2>
                <p>
                    En transmettant vos données à Soumissions Avocat par le
                    biais d’un formulaire en ligne, par téléphone ou par
                    courriel, vous consentez à ce que celles-ci soient
                    intégralement retransmises à certains nos partenaires afin
                    qu’il vous fasse une ou des offres par courriel et/ou
                    téléphone.
                </p>
                <p>
                    En nous transmettant votre numéro de téléphone par le biais
                    de notre formulaire en ligne ou par courriel, vous acceptez
                    d’être contacté par les entreprises partenaires, et ce, même
                    si vous êtes inscrit sur la liste nationale de
                    télécommunication exclue.
                </p>
                <p>
                    Soumissions Avocat s’engage à faire un usage raisonnable,
                    non abusif, de vos coordonnées afin de vous contacter. Vous
                    acceptez de recevoir notre message de confirmation,
                    d’évaluation de la satisfaction et les autres messages de
                    l’équipe de Soumissions Avocat.
                </p>
                <p>
                    Vous pouvez en tout temps être retiré de notre liste de
                    contacts en nous écrivant par courriel (voir nos coordonnées
                    plus bas).
                </p>
                <p>
                    Vous acceptez que nos partenaires effectuent les
                    vérifications d’usage avec vos informations (antécédents de
                    réclamations d’assurance, bureau de crédit, permis de
                    conduire…).
                </p>
                <p>
                    Vos données ne seront pas données ou partagées à d’autres
                    parties tierces.
                </p>
                <p>
                    Soumissions Avocat se réserve le droit mondial, irrévocable,
                    perpétuel et non rémunéré de conserver et les données
                    transmises par les utilisateurs du service.
                </p>
                <h2>UTILISATION DES COOKIES</h2>
                <p>
                    Le site Web de Soumissions Avocat utilise les « cookies ».
                </p>
                <p>
                    Un cookie ou fichier témoin est un petit fichier texte
                    enregistré sur le disque dur du visiteur d’un site comme
                    celui-ci. Aucune information confidentielle n’est prise. Ce
                    n’est pas dangereux ou nocif pour votre système. Au
                    contraire, les cookies sont utilisés pour personnaliser et
                    ainsi améliorer votre expérience de navigation sur cette
                    plate-forme web. Si vous ne désirez plus être sujet aux
                    cookies, vous pouvez les désactiver via les options de votre
                    navigateur (qu’il s’agisse de Google Chrome, Mozilla
                    Firefox, Internet Explorer ou autre).
                </p>
                <p>
                    Soumissions Avocat utilise Google Analytics et d’autres
                    outils de suivi Web, afin d’analyser le profil et le
                    comportement des utilisateurs. Les données ainsi générées
                    sont transmises et conservées par Google.
                </p>
                <p>
                    Google pourrait avoir à communiquer ces données à des tiers
                    s’il en était contraint légalement ou bien si ces tiers
                    traitent ces données pour Google.
                </p>
                <h2>VALIDATION DES SOUMISSIONS OU INFORMATIONS</h2>
                <p>
                    Soumissions Avocat ne garantit pas l’exactitude des offres
                    transmises par nos partenaires. Elles doivent être validées
                    avec les partenaires.
                </p>
                <p>
                    Avant de signer un contrat avec l’un de nos partenaires, il
                    est de votre responsabilité d’exiger de vérifier ses permis,
                    certifications ou accréditations, puis de poser les bonnes
                    questions pour bien comprendre l’analyse de vos besoins.
                </p>
                <p>
                    Soumissions Avocat ne garantit pas l’exactitude des contenus
                    sur son site ou tout autre de ses plateformes. Nous nous
                    efforcerons tout de même de les maintenir à jour. Si vous
                    désirez signaler une erreur, contactez-nous.
                </p>
                <p>
                    Les renseignements affichés sur le site et toute autre
                    plateforme de Soumissions Avocat sont fournis à titre
                    informatif et n’ont aucune valeur officielle ou
                    professionnelle. Nous vous recommandons de consulter un
                    professionnel.
                </p>
                <p>
                    Les opinions ou commentaires dans les pages et articles du
                    site de Soumissions Avocat sont ceux des rédacteurs ou bien
                    de la personne auxquels ils sont attribués. Soumissions
                    Avocat n’en est pas responsable.
                </p>
                <h2>UTILISATION LÉGITIME DU SERVICE</h2>
                <p>
                    Les partenaires et les utilisateurs du service (demandeurs)
                    s’engagent à:
                </p>
                <ul>
                    <li>fournir des informations légitimes</li>
                    <li>respecter la législation en vigueur en tout temps</li>

                    <li>
                        ne pas compromettre la sécurité, l’accessibilité et
                        l’intégrité du site Internet de quelque façon que ce
                        soit (logiciels malveillants, pourriels, virus, etc.)
                    </li>

                    <li>
                        ne pas représenter une autre personne ou entreprise sans
                        son consentement
                    </li>

                    <li>
                        ne pas utiliser un robot ou tout autre moyen
                        électronique pour collecter des données
                    </li>

                    <li>
                        ne pas contourner les mesures de protection destinées à
                        restreindre l’accès sur une ou des parties du site Web
                        de Soumissions Avocat
                    </li>
                </ul>
                <p>
                    En utilisant notre service, l’utilisateur du service
                    s’engage à ne pas soumettre de demande fictive pour recevoir
                    de l’information de Soumissions Avocat ou de nos
                    partenaires.
                </p>
                <p>
                    Dans le cas où des renseignements faux ou erronés soient
                    transmis par l’utilisateur du service (demandeur) à
                    Soumissions Avocat, l’utilisateur du service est dans
                    l’obligation d’indemniser Soumissions Avocat pour tout
                    recours ou demande en justice contre notre entreprise ou
                    service. Cette indemnisation doit couvrir les frais
                    judiciaires, extrajudiciaires et les frais d’avocats.
                </p>
                <p>
                    En cas d’attaque sous forme de virus ou tout autre préjudice
                    à l’intégrité du site Web ou de toute autre plateforme de
                    Soumissions Avocat, nous engagerons des poursuites pour
                    dommages et intérêts, et ce, sans préavis.
                </p>
                <h2>ACTIONS POSÉES SUR LA FOI DES CONTENUS DU SITE.</h2>
                <p>
                    Soumissions Avocat se dégage de toute responsabilité
                    relative aux dommages qui pourraient découler d’actions
                    posées sur la foi des renseignements affichés sur notre site
                    Web ou nos plateformes.
                </p>
                <h2>DISPONIBILITÉ & SÉCURITÉ DU SITE</h2>
                <p>
                    Soumissions Avocat ne garantit pas la disponibilité ou le
                    fonctionnement du site Web ou de toute autre de ses
                    plateformes.
                </p>
                <p>
                    Soumissions Avocat ne garantit pas que les fichiers hébergés
                    sur notre serveur ne causeront pas d’erreur, qu’ils ne
                    contiennent pas de virus informatique, de cheval de Troie ou
                    d’autres éléments nocifs pour votre matériel informatique.
                </p>
                <p>
                    Soumissions Avocat se réserve le droit de mettre fin à ses
                    activités sans préavis, pour quelque raison que ce soit.
                </p>
                <h2>DÉRESPONSABILISATION EN LIEN AUX SITES EXTERNES</h2>
                <p>
                    Soumissions Avocat n’endosse pas et n’approuve pas
                    nécessairement les contenus affichés sur les sites Web ou
                    toute autre plateforme vers lesquels des liens hypertextes
                    mènent.
                </p>
                <p>
                    Lorsque vous visitez ces sites externes à Soumissions
                    Avocat, c’est à vos risques.
                </p>
                <h2>DROITS D’AUTEUR</h2>
                <p>
                    Le site Web et les contenus de Soumissions Avocat sont
                    protégés par le droit d’auteur. Une permission écrite doit
                    être obtenue de Soumissions Avocat pour les utiliser. Il est
                    permis de partager les contenus sur les médias sociaux.
                </p>
                <p>
                    Soumissions Avocat se réserve le droit de modifier ou de
                    retirer du contenu du site Web ou de tout autre de ses
                    plateformes, et ce, sans préavis.
                </p>
                <p>
                    Soumissions Avocat se réserve le droit d’utiliser les idées
                    qui lui parviennent de la part des utilisateurs (demandeurs)
                    ou de ses partenaires, qu’elles soient en lien au
                    développement des affaires, au marketing, au développement
                    du service ou autre.
                </p>
                <h2>LITIGE JUDICIAIRE</h2>
                <p>
                    Dans le cas où un litige survient relativement à
                    l’application des modalités sur cette page, tout recours
                    devra avoir lieu dans le district judiciaire de Québec.
                </p>
                <p>
                    Vous vous engagez à indemniser Soumissions Avocat, ses
                    dirigeants et ses partenaires en cas de réclamation (et
                    frais d’avocats) résultant de votre utilisation du site de
                    Soumissions Avocat ou en cas de mise en cause de leur
                    responsabilité.
                </p>
                <h2>RESPONSABILITÉ GÉNÉRALE</h2>
                <p>
                    Les actionnaires, administrateurs, employés et autres
                    personnes associées à Soumissions Avocat ne peuvent être
                    tenus responsables des dommages résultant de leur emploi,
                    quelle qu’en soit la cause.
                </p>
                <p>
                    N’hésitez pas à nous contacter pour tout renseignement
                    supplémentaire.
                </p>
                <p>
                    <strong>Coordonnées de Soumissions Avocat:</strong>
                </p>
                <ul>
                    <li>https://soumissionsavocat.ca/</li>
                    <li>contact@soumissionsavocat.ca</li>
                </ul>
            </Content>
        </Layout>
    )
}

export default Terms

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
